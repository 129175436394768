<template>
  <div
    class="modal fade"
    id="modalAccountEstimated"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Rincian Penerimaan Barang</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label class=""
                >{{ formDetail.kode_barang }} - {{ formDetail.nama_barang }}
              </label>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Qty PO </label>
              <div class="row">
                <div class="col-6" style="padding-right: 0px">
                  <input
                    disabled
                    type="text"
                    class="form-control text-right"
                    placeholder="Qty PO"
                    v-model="formDetail.qty_po"
                  />
                </div>
                <div class="col-6">
                  <Select2
                    disabled
                    v-model="formDetail.id_satuan"
                    :options="optionUnit"
                    placeholder="Satuan"
                    :settings="{ settingOption: value, settingOption: value }"
                    @change="myChangeEvent($event)"
                    @select="mySelectEvent($event)"
                  />
                </div>
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Qty Diterima </label>
              <div class="row">
                <div class="col-6" style="padding-right: 0px">
                  <input
                    type="text"
                    class="form-control text-right"
                    placeholder="Qty Diterima"
                    v-model="formDetail.qty_terima"
                    v-maska="'###########'"
                    @keyup="formChange('qty_terima', $event, 'detail')"
                    :class="{
                      'is-invalid':
                        formErrorDetail && formErrorDetail.qty_terima,
                    }"
                  />
                </div>
                <div class="col-6">
                  <Select2
                    v-model="formDetail.id_satuan"
                    :options="optionUnit"
                    placeholder="Satuan"
                    disabled
                    @change="myChangeEvent($event)"
                    @select="mySelectEvent($event)"
                  />
                </div>
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Gudang </label>
              <Select2
                style="width: 100%"
                v-model="formDetail.id_gudang"
                :options="optionWerehouse"
                placeholder="Pilih Gudang"
                @change="formChange('id_gudang', $event, 'detail')"
                @select="formChange('id_gudang', $event, 'detail')"
                :class="{
                  'is-invalid': formErrorDetail && formErrorDetail.id_gudang,
                }"
              />
            </div>

            <!-- <div class="form-group d-flex align-items-center">
              <label class="form-label">Departemen </label>
              <Select2
                style="width: 100%"
                v-model="formDetail.id_divisi"
                :options="optionDepartement"
                placeholder="Pilih Departemen"
                @change="formChange('id_divisi', $event, 'detail')"
                @select="formChange('id_divisi', $event, 'detail')"
                :class="{
                  'is-invalid': formErrorDetail && formErrorDetail.id_divisi,
                }"
              />
            </div> -->
          </div>
          <div class="modal-footer">
            <button type="button" @click="saveBarang" class="btn btn-save">
              Tambah Data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="modalDetailGoods"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Rincian Barang</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Kode Barang</label>
              <div class="value">
                {{ formModal.kode_barang ? formModal.kode_barang : "-" }}
              </div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Nama Barang</label>
              <div class="value">
                {{ formModal.nama_barang ? formModal.nama_barang : "-" }}
              </div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Qty PO</label>
              <div class="value">
                {{ formModal.qty_po ? formModal.qty_po : "-" }}
              </div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Qty Diterima</label>
              <div class="value">
                {{ formModal.qty_terima ? formModal.qty_terima : "-" }}
              </div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Gudang</label>
              <div class="value">
                {{ formModal.gudang ? formModal.gudang : "-" }}
              </div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Departemen</label>
              <div class="value">
                {{ formModal.departement ? formModal.departement : "-" }}
              </div>
            </div>

            <!-- <div class="form-group d-flex align-items-center">
              <label class="form-label">Status</label>
              <div class="orange-bedge">Diproses</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelPage }} Penerimaan Barang</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-penerimaan-supply"
                      >Penerimaan Barang
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }}
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <button
                :disabled="isSubmit"
                v-if="onEditable"
                class="btn btn-save"
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card-table info-detail">
            <div class="row mt-3">
              <div class="col-12">
                <div class="title">{{ labelPage }} Penerimaan Barang</div>
                <div class="sub-title" v-if="onEditable">
                  {{ labelPage }} informasi yang diperlukan
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="formData.kode">
                  <label>Nomor:</label>
                  <input
                    type="text"
                    v-model="formData.kode"
                    disabled
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Nomor PO :</label>
                  <Select2
                    v-if="onEditable"
                    v-model="formData.no_po"
                    :options="optionPO"
                    placeholder="Pilih Nomor PO"
                    @change="formChange('no_po', $event, '')"
                    @select="formChange('no_po', $event, '')"
                    :class="{ 'is-invalid': formError && formError.no_po }"
                  />
                  <input
                    v-if="!onEditable"
                    type="text"
                    v-model="formData.no_po"
                    disabled
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>Supplier :</label>
                  <Select2
                    v-model="formData.id_vendor"
                    :options="optionSupplier"
                    :disabled="formData.no_po != '' || !onEditable"
                    placeholder="Pilih Supplier"
                    @change="formChange('id_vendor', $event, '')"
                    @select="formChange('id_vendor', $event, '')"
                    :class="{ 'is-invalid': formError && formError.id_vendor }"
                  />
                </div>
                <div class="form-group">
                  <label>Akun Temporary</label>
                  <Select2
                    v-model="formData.akun_temporary"
                    :disabled="!onEditable"
                    :class="{ 'is-invalid': formError && formError.akun_temporary }"
                    :options="OptionAccountEstimated"
                    placeholder="List Project"
                    :settings="{ templateResult: formatState }"
                    @change="formChange('akun_temporary')"
                    @select="formChange('akun_temporary')"
                  />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label class="">Nomor Nota :</label>
                  <input
                    type="text"
                    class="form-control"
                    :disabled="!onEditable"
                    placeholder="Masukkan Nomor Nota"
                    v-model="formData.no_nota"
                    @keyup="formChange('no_nota', $event, '')"
                    :class="{ 'is-invalid': formError && formError.no_nota }"
                  />
                </div>

                <div class="form-group">
                  <label>Tanggal Terima :</label>
                  <Datepicker
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.tanggal_terima"
                    placeholder="Tanggal Terima"
                    :maxDate="new Date()"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    :disabled="!onEditable"
                    cancelText="Batal"
                    :class="{
                      'is-invalid': formError && formError.tanggal_terima,
                    }"
                    @update:modelValue="changeDate($event, 'tanggal_terima')"
                  ></Datepicker>
                </div>
                <div class="form-group">
                  <label class="">Keterangan :</label>
                  <textarea
                    type="text"
                    class="form-control"
                    placeholder="Masukkan Keterangan"
                    :disabled="!onEditable"
                    v-model="formData.keterangan"
                  />
                </div>
              </div>
            </div>

            <div class="row align-items-center mt-5">
              <div class="col-12">
                <div class="title">Rincian Barang</div>
                <div class="sub-title">
                  Rincian dari rincian barang yang diperlukan
                </div>
              </div>
            </div>
            <hr />

            <div class="search">
              <div class="form-group" v-if="onEditable" style="width: 400px">
                <Select2
                  v-model="goodsItem"
                  :options="optionGoods"
                  placeholder="Pilih Barang"
                  :settings="{ templateResult: formatState }"
                  @change="myChangeEvent()"
                  @select="mySelectFormState($event)"
                />
              </div>
            </div>
            <div class="">
              <table
                class="table table-bordered table-striped table-hover"
                id="tablePO"
              >
                <thead>
                  <tr>
                    <th style="width: 20%">Kode</th>
                    <th style="width: 35%">Nama Barang</th>
                    <th style="width: 10%">Qty PO</th>
                    <th style="width: 10%">Qty Diterima</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(value, index) in listDetail"
                    :key="index"
                    v-on:click="detail(value, $event)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Klik untuk melihat detail"
                  >
                    <td>{{ value.kode_barang }}</td>
                    <td>{{ value.nama_barang }}</td>
                    <td class="text-right">
                      {{ `${value.qty_po} ${value.nama_satuan}` }}
                    </td>
                    <td class="text-right position-relative">
                      {{ `${value.qty_terima} ${value.nama_satuan}` }}
                      <div
                        v-if="onEditable"
                        style="
                          position: absolute;
                          right: -30px;
                          top: 50%;
                          transform: translateY(-50%);
                        "
                      >
                        <img
                          src="/img/avatar/icon-delete.svg"
                          @click="deleteRow(index)"
                          class="btn-add-row ml-1"
                          style="cursor: pointer; width: 20px"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr v-if="listDetail.length == 0">
                    <td colspan="4" class="text-center">Tidak ada data</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import { checkRules, cksClient, showAlert } from "../../../../helper";
import moment from "moment";
import "moment/locale/id";
import { get_PurchaseOrderList } from "../../../../actions/barang/purchaseOrder";
import { get_ListVendor } from "../../../../actions/vendor";

import {
  get_BarangPenerimaanDetail,
  get_PenerimaanBarangList,
  post_BarangPenerimaanSave,
} from "../../../../actions/barang/penerimaan";
import { get_ListDivisi, get_SatuanList } from "../../../../actions/master";
import { get_GudangList } from "../../../../actions/company/gudang";
import { maska } from "maska";
import { get_AkunDefault, get_AkunList } from '../../../../actions/akun_perkiraan';
// import TableLite from 'vue3-table-lite'

export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  directives: {
    maska,
  },

  data() {
    return {
      optionPO: [],
      optionSupplier: [],
      optionGoods: [],
      optionUnit: [],
      optionWerehouse: [],
      optionDepartement: [],
      OptionAccountEstimated: [],
      id_company: cksClient().get("_account").id_company,
      onEditable: true,
      kode: this.$route.params.kode ? this.$route.params.kode : "",
      labelPage: this.$route.params.kode ? "Detail" : "Tambah",
      //formData
      isSubmit: false,
      formData: {
        no_po: this.$route.params.kode_po ? this.$route.params.kode_po : "",
        tanggal_terima: "",
        id_vendor: "",
        id_company: cksClient().get("_account").id_company,
        no_nota: "",
        keterangan: "",
        status: "aktif",
        akun_temporary: "",
        persediaan: "true",
      },
      rules: {
        no_po: {
          required: false,
        },
        tanggal_terima: {
          required: true,
        },
        no_nota: {
          required: true,
        },
        keterangan: {
          required: false,
        },
        akun_temporary: {
          required: true,
        },
      },
      formError: [],
      formDetail: {
        kode_barang: "",
        nama_barang: "",
        id_satuan: "",
        nama_satuan: "",
        id_divisi: "",
        nama_divisi: "",
        id_gudang: "",
        nama_gudang: "",
        qty_po: "",
        qty_terima: "",
      },
      goodsItem: "",
      formErrorDetail: [],
      rulesDetail: {
        kode_barang: {
          required: true,
        },
        id_satuan: {
          required: true,
        },
        id_divisi: {
          required: false,
        },
        id_gudang: {
          required: true,
        },
        qty_po: {
          required: false,
        },
        qty_terima: {
          required: true,
          max: 100000000000000,
        },
      },
      listDetail: [],
      formModal: {
        kode_barang: "",
        nama_barang: "",
        qty_po: "",
        qty_terima: "",
        gudang: "",
        departement: "",
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    // $("#tablePO").DataTable({
    //   info: false,
    // });
    this.tooltip();
  },

  created() {
    this.getPo();
    this.getSupplier();
    this.getSatuan();
    this.getBarang();
    this.getGudang();
    this.getDivisi();
    this.getAkun();
    if (this.kode) {
      this.getData();
    } else {
      this.getDefaultAkun();
    }
  },

  methods: {
    getData() {
      get_BarangPenerimaanDetail(this.kode, (res) => {
        var { data, detail } = res;
        this.formData = data;
        this.onEditable = false;
        this.listDetail = detail;
      });
    },
    //master
    getDefaultAkun() {
      var name_id = "akun_temporary_barang";
      get_AkunDefault(
        {
          id_company: this.id_company,
          name_id: name_id,
        },
        (res) => {
          var list = res.list;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              if (key == 0) {
                this.formData.akun_temporary = element;
              }
            }
          }
        }
      );
    },
    getAkun(tipe = "") {
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: tipe,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          // console.log(data); 
          this.OptionAccountEstimated = data;
        }
      );
    },
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.optionDepartement = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionDepartement.push(element);
          }
        }
      });
    },
    getGudang() {
      get_GudangList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          var { list } = res;
          this.optionWerehouse = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionWerehouse.push({
                id: element.id,
                text: element.nama,
                alamat: element.alamat,
              });
            }
          }
        }
      );
    },
    getPo() {
      get_PurchaseOrderList(
        {
          id_company: this.id_company,
          penerimaan: true,
          isPersediaan: true,
        },
        (res) => {
          var { list } = res;
          this.optionPO = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              if (this.formData.no_po == element.kode) {
                this.formData.id_vendor = element.id_vendor;
                this.formDetail.id_divisi = element.id_divisi;
                this.formDetail.nama_divisi = element.nama_divisi;
              }
              this.optionPO.push({
                id: element.kode,
                text: element.kode,
                id_vendor: element.id_vendor,
                nama_vendor: element.nama_vendor,
                id_divisi: element.id_divisi,
                nama_divisi: element.nama_divisi,
              });
            }
          }
        }
      );
    },

    getSupplier() {
      get_ListVendor(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionSupplier = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionSupplier.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },

    getSatuan() {
      get_SatuanList(
        (res) => {
          var { list } = res;
          this.optionUnit = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionUnit.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        },
        (e) => {
          console.log(e);
        }
      );
    },

    getBarang() {
      get_PenerimaanBarangList(this.formData.no_po, this.id_company, (res) => {
        var { list } = res;
        this.optionGoods = list;
      });
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(value, $event) {
      if ($event.target.className != "btn-add-row ml-1") {
        this.formModal.kode_barang = value.kode_barang;
        this.formModal.nama_barang = value.nama_barang;
        this.formModal.qty_po = `${value.qty_po} ${value.nama_satuan}`;
        this.formModal.qty_terima = `${value.qty_terima} ${value.nama_satuan}`;
        this.formModal.gudang = value.nama_gudang;
        this.formModal.departement = value.nama_divisi;
        $("#modalDetailGoods").modal("show");
        $('[data-toggle="tooltip"]').tooltip("hide");
      }
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var check = this.searchObjectBarang(state.id);
      if (!check) {
        var $state = $(
          "<div>" +
            state.text +
            '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
            state.id +
            "</div></div>"
        );
        return $state;
      } else {
        return null;
      }
    },

    searchObjectBarang(what) {
      return this.listDetail.find((element) => element.kode_barang == what);
    },

    changeDate(data, key, tipe = "") {
      if (tipe == "detail") {
        this.formDetail[key] = moment(data).format("YYYY-MM-DD");
      } else {
        this.formData[key] = moment(data).format("YYYY-MM-DD");
      }
      this.formChange(key, "", tipe);
    },

    async formChange(key, val = "", tipe = "") {
      var check = "";
      if (tipe == "detail") {
        if (this.rulesDetail[key]) {
          this.rulesDetail[key].changed = true;
        }
        check = await checkRules(this.rulesDetail, this.formDetail);
        this.formErrorDetail = check.error;
        if (key === "id_gudang") {
          this.formDetail.nama_gudang = val.text;
        }
        if (key === "id_divisi") {
          this.formDetail.nama_divisi = val.text;
        }
      } else {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }
        if (key == "no_po") {
          this.formData.id_vendor = val.id_vendor;
          this.formDetail.id_divisi = val.id_divisi;
          this.formDetail.nama_divisi = val.nama_divisi;
          this.getBarang();
        }
        check = await checkRules(this.rules, this.formData);
        this.formError = check.error;
      }
    },

    async saveBarang() {
      for (const key in this.rulesDetail) {
        if (Object.hasOwnProperty.call(this.rulesDetail, key)) {
          this.rulesDetail[key].changed = true;
        }
      }
      var check = await checkRules(this.rulesDetail, this.formDetail);
      this.formErrorDetail = check.error;
      if (check.success) {
        var data = { ...this.formDetail };
        this.listDetail.push(data);
        $("#modalAccountEstimated").modal("hide");
      }
    },

    deleteRow(index) {
      this.listDetail.splice(index, 1);
    },

    myChangeEvent() {},

    mySelectFormState(val) {
      this.formDetail.kode_barang = val.id;
      this.formDetail.nama_barang = val.text;
      this.formDetail.id_satuan = val.id_satuan;
      this.formDetail.nama_satuan = val.nama_satuan;
      this.formDetail.qty_po = val.qty_po ? val.qty_po : 0;
      this.formDetail.qty_terima = "";
      if (this.formDetail.qty_po) {
        this.rulesDetail.qty_terima.max = parseInt(this.formDetail.qty_po);
      } else {
        this.rulesDetail.qty_terima.max = 100000000000000000000;
      }
      setTimeout(() => {
        this.goodsItem = "";
      }, 500);
      $("#modalAccountEstimated").modal("show");
    },

    postData() {
      this.isSubmit = true;
      var data = { ...this.formData };
      data.detail = this.listDetail;
      post_BarangPenerimaanSave(
        data,
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: "Data penerimaan berhasil dicatat",
            onSubmit: () => {
              if (this.$route.params.kode_po) {
                this.$router.push({
                  name: "DetailPOSupply",
                  params: {
                    kode: this.$route.params.kode_po,
                  },
                });
              } else {
                this.$router.push({
                  name: "DataReceptionSupply",
                });
              }
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      var length = this.listDetail.length;
      this.formError = check.error;
      if (check.success && length > 0) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      } else {
        if (length == 0) {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Isi terlebih dahulu rincian barang, sebelum menyimpan",
            showConfirmButton: false,
          });
        }
      }
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.value {
  font-size: 13px;
}
</style>
